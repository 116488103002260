import React, { useEffect } from 'react';
import QuoteFrame from '../components/Quote/QuoteFrame';
import NavBar from '../components/Header/NavBar';

const Converter = () => {
  return (
    <>
      <header className="global-header" style={{ display: 'none' }}>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Montserrat:200,300,500,700&display=swap"
        />
        <NavBar />
      </header>
      <QuoteFrame />
    </>
  );
};

export default Converter;
