import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiFilledInput-underline:before': {
        borderBottom: 0,
      },
      '.MuiFilledInput-underline:after': {
        borderBottom: 0,
      },
      '.MuiFormLabel-root.Mui-focused': {
        color: '#296B88',
      },
    },
    container: {
      display: 'grid',
    },
    quoteSection: {
      paddingTop: '10px',
      backgroundColor: '#f8f8f9',
    },
    option: {
      fontSize: 21,
      '& > span': {
        marginRight: 10,
        fontSize: 21,
      },
    },
    input: {
      fontSize: 35,
      padding: 5,
      width: 100,
    },
    sendSection: {
      width: '100%',
      color: '#646464',
      fontSize: '12px',
      fontWeight: 400,
    },
    quoteAmount: {
      width: `80%`,
    },
    quoteCurrency: {
      width: `20%`,
    },
    quoteMessage: {
      width: 162,
    },
    ammount: {
      fontSize: 35,
      marginTop: theme.spacing(0),
      fontWeight: 'bold',
      height: 43,
      backgroundColor: '#E5EDF1',
      color: '#296B88',
      fontFamily: 'Montserrat',
      [theme.breakpoints.down(1364)]: {
        fontSize: 28,
      },
      [theme.breakpoints.down(1000)]: {
        fontSize: 25,
      },
    },
    ammountTo: {
      fontSize: 35,
      marginTop: theme.spacing(0),
      fontWeight: 'bold',
      backgroundColor: '#E5EDF1',
      height: 43,
      color: '#296B88',
      fontFamily: 'Montserrat',
      [theme.breakpoints.down(1364)]: {
        fontSize: 28,
      },
      [theme.breakpoints.down(1000)]: {
        fontSize: 25,
      },
    },
    popper: {
      width: 300,
    },
    control: {
      padding: theme.spacing(2),
      borderRadius: 0,
      paddingBottom: 2,
    },
    quoteInfo: {
      padding: 0,
      borderRadius: 0,
      paddingBottom: 2,
    },
    // [theme.breakpoints.down(1364)]: {
    //     quoteAmount: {
    //       width:'20%'
    //     },
    //     quoteCurrency: {
    //       width:'10%'
    //     },
    //     ammount: {
    //         fontSize:24,
    //     },
    //     ammountTo: {
    //         fontSize:24,
    //     }
    // },
    // [theme.breakpoints.down(1280)]: {
    //     quoteAmount: {
    //       width:'30%'
    //     },
    //     quoteCurrency: {
    //       width:'20%'
    //     },
    //     ammount: {
    //         fontSize:24,
    //     },
    //     ammountTo: {
    //         fontSize:24,
    //     }
    // },
    [theme.breakpoints.down(769)]: {
      quoteAmount: {
        width: '70%',
        marginTop: '10px',
      },
      quoteCurrency: {
        marginTop: '10px',
        width: '30%',
      },
      ammount: {
        fontSize: 24,
      },
      ammountTo: {
        fontSize: 24,
      },
      control: {
        padding: 0,
        borderRadius: 0,
      },
    },

    // },
    // [theme.breakpoints.down(500)]: {
    //     quoteAmount: {
    //       width:'70%'
    //     },
    //     quoteCurrency: {
    //       width:'30%'
    //     },
    //     ammount: {
    //         fontSize:40,
    //     },
    //     ammountTo: {
    //         fontSize:40,
    //     }
    // }
  })
);

export default useStyles;
