// Minimum requeriments
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { TextField, Grid } from '@material-ui/core';
import Currency from './Currency';
import { APICurrency, APIQuote } from '../../services/APICurrency.service';
import useStyles from './QuoteFrame.styles';
import { ICurrencyData, IQuoteData } from '../../types';

const NumberFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      prefix={'$'}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      // isNumericString
    />
  );
};

const NumberFormatToCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      // isNumericString
    />
  );
};

const countryToFlag = (isoCode: string) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
};

const QuoteFrame: React.FC = () => {
  const [currenciesFrom, setCurrenciesFrom] = useState<ICurrencyData[] | null>([]);
  const [currenciesTo, setCurrenciesTo] = useState<ICurrencyData[] | null>([]);
  const firstQuote = true;
  const [valueFrom, setValueFrom] = useState<string | null>(null);
  const [valueTo, setValueTo] = useState<string | null>(null);
  const [exchangeRate, setExchangeRate] = useState<string | null>(null);
  const [estimatedDate, setEstimatedDate] = useState<string | null>(null);
  const [quoteType, setQuoteType] = useState<string | null>(null);
  const [currencyFrom, setCurrencyFrom] = useState<ICurrencyData | null>(null);
  const [currencyTo, setCurrencyTo] = useState<ICurrencyData | null>(null);
  const [messageFromError, setMessageFromError] = useState<string | null>('');
  const [messageFromErrorIsVisible, setMessageFromErrorIsVisible] = useState<boolean | null>(false);
  const [exchangeRateIsVisible, setExchangeRateIsVisible] = React.useState<boolean | null>(false);
  const [estimatedDateIsVisible, setEstimatedDateisVisible] = React.useState<boolean | null>(false);
  const tooltipMessage =
    'Este plazo es referencial. La fecha de llegada de los fondos a destino puede variar debido a los feriados nacionales e internacionales.';
  const getCurrencyList = async () => {
    const dataFrom = [
      { isoCode: 'CL', currency: 'CLP', id: 'CLP', countryName: 'Chile', popular: true },
    ];
    const { data: dataTo } = await APICurrency.get<ICurrencyData[]>('/');
    const mappedDataTo = _.sortBy(
      dataTo.map(({ id, isoCode, currency, popular }) => ({
        id,
        currency,
        isoCode,
        countryName: new Intl.DisplayNames(['es'], {
          type: 'region',
        }).of(isoCode) as string,
        popular,
      })),
      ['popular', 'countryName', 'currency']
    );
    setCurrenciesFrom(dataFrom);
    setCurrenciesTo(mappedDataTo);
  };
  const getQuote = async (
    amount: number,
    quoteType: string,
    countryCode: string,
    currency: string,
    firstQuote = false
  ) => {
    if (!amount || amount <= 0) {
      setMessageFromError('El monto de origen es inferior al permitido');
      setMessageFromErrorIsVisible(true);
      return;
    }
    if (!quoteType || !countryCode) {
      return;
    }
    try {
      const quote = await APIQuote.get<IQuoteData>('/', {
        params: {
          amount: amount,
          quoteType: quoteType,
          originCountry: 'CL',
          originCurrency: 'CLP',
          destinationCountry: countryCode,
          destinationCurrency: currency,
        },
      });

      const data = {
        status: quote.data['status'],
        value: {
          origin: quote.data['value'],
          usd: quote.data['dofRequired'],
        },
        symbol: quote.data['symbol'],
        estimatedDate: quote.data['estimatedDate'],
        exchangeRate: quote.data['exchangeRate'],
      };

      if (data.status === 0) {
        setMessageFromError('El monto de origen es inferior al permitido');
        setMessageFromErrorIsVisible(true);
        setExchangeRateIsVisible(false);
        setEstimatedDateisVisible(false);
      } else if (data && data.status != 0) {
        if (!firstQuote) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'getQuotes',
            quote_type: quoteType,
            destination_country_home: countryCode,
            destination_currency_home: currency,
            destination_amount_home:
              quoteType === 'sell' ? Number(quote.data['value']) : Number(amount),
            origin_country_home: 'CL',
            origin_currency_home: 'CLP',
            origin_amount_home: quoteType === 'sell' ? Number(amount) : Number(quote.data['value']),
          });
          // window.fbq('trackCustom', 'getQuotes', {codigo_pais: countryCode, value: amount});
        }
        if (data.estimatedDate) {
          setEstimatedDate(data.estimatedDate);
          setEstimatedDateisVisible(true);
        }
        setMessageFromErrorIsVisible(false);
        if (quoteType === 'sell') {
          setValueTo(data.value.origin.toString());
        } else if (quoteType === 'buy') {
          setValueFrom(data.value.origin.toString());
        }
        setExchangeRateIsVisible(true);
        setExchangeRate(data.exchangeRate.toString());
      }
    } catch {
      setMessageFromError('Ha ocurrido un error en la cotización');
      setMessageFromErrorIsVisible(true);
    }
  };

  useEffect(() => {
    getCurrencyList();
    currencyDefault();
  }, []);

  const currencyDefault = () => {
    setQuoteType(null);
    const amount = 500000;
    setValueFrom(amount.toString());
    setQuoteType('sell');
    delayedQuote(amount, 'sell', 'US', 'USD', firstQuote);
  };

  const delayedQuote = useCallback(
    _.debounce(
      (
        amount: number,
        quoteType: string,
        countryCode: string,
        currency: string,
        firstQuote: boolean
      ) => getQuote(amount, quoteType, countryCode, currency, firstQuote),
      700
    ),
    []
  );

  const onChangeFrom = (e: any) => {
    setQuoteType(null);
    const amount: number = parseInt(e.target.value);
    setValueFrom(e.target.value);
    if (currencyTo) {
      if (!quoteType || quoteType === 'sell') {
        setQuoteType('sell');
        delayedQuote(amount, 'sell', currencyTo.id, currencyTo.currency, false);
      }
    }
  };
  const onChangeTo = (e: any): void => {
    setQuoteType(null);
    const amount: number = parseInt(e.target.value);
    setValueTo(e.target.value);
    if (currencyTo) {
      if (!quoteType || quoteType === 'buy') {
        setQuoteType('buy');
        delayedQuote(amount, 'buy', currencyTo.isoCode, currencyTo.currency, false);
      }
    }
  };

  const currencyOnChange = (quoteType: string, currency: ICurrencyData) => {
    if (valueFrom) {
      const amount: number = parseInt(valueFrom.toString());
      if (quoteType === 'buy' && currency) {
        setQuoteType('sell');
        getQuote(amount, 'sell', currency.isoCode, currency.currency);
      } else {
        setValueTo('');
      }
    }
  };

  const classes = useStyles();
  return (
    <Grid className={classes.quoteSection} container spacing={0}>
      <Grid className={classes.quoteAmount} item>
        <TextField
          id="amount-from"
          value={valueFrom}
          label="Tú envías:"
          fullWidth
          defaultValue="500000"
          onChange={onChangeFrom}
          InputProps={{
            classes: { input: classes.ammount },
            inputComponent: NumberFormatCustom,
          }}
          InputLabelProps={{
            style: { color: '#296B88', paddingBottom: '5px' },
          }}
          variant="filled"
        />
      </Grid>
      <Grid item className={classes.quoteCurrency}>
        <Currency
          currencies={currenciesFrom}
          setCurrency={setCurrencyFrom}
          quoteType="sell"
          currencyOnChange={currencyOnChange}
        />
      </Grid>

      <Grid className={classes.quoteAmount} item>
        <TextField
          id="amount-to"
          value={valueTo}
          label="El destinatario recibe:"
          fullWidth
          defaultValue=""
          onChange={onChangeTo}
          InputProps={{
            classes: { input: classes.ammountTo },
            inputComponent: NumberFormatToCustom,
          }}
          InputLabelProps={{
            style: { color: '#296B88', paddingBottom: '5px' },
          }}
          variant="filled"
        />
      </Grid>
      <Grid item className={classes.quoteCurrency}>
        <Currency
          currencies={currenciesTo}
          setCurrency={setCurrencyTo}
          quoteType="buy"
          currencyOnChange={currencyOnChange}
        />
      </Grid>
    </Grid>
  );
};

export default QuoteFrame;
